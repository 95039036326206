'use client';
import React, { useContext } from 'react';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import Link from '../Link/Link';
import { Typography } from '../Typography';

type SponsoredHeaderProps = {
  readonly text?: string;
  readonly link?: string;
  readonly logoImage?: string;
  readonly logoLink?: string;
};

export const SponsoredHeader = ({ text, link, logoImage, logoLink }: SponsoredHeaderProps) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./SponsoredHeaderNew.module.scss') : require('./SponsoredHeader.module.scss');

  if (!link && !logoLink) {
    return <></>;
  }

  return (
    <div className={styles.sponsoredHeaderWrapper}>
      {text &&
        (link ? (
          <Link href={link} plainLink target="_blank" title={text}>
            {text}
          </Link>
        ) : (
          <Typography variant="3xs">{text}</Typography>
        ))}
      {logoImage && (
        <div className={styles.imageWrapper}>
          <Typography variant="6xs">präsentiert von</Typography>
          {logoLink ? (
            <Link href={logoLink} plainLink title={`${text} - Logo`} target="_blank">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={logoImage} alt={text ?? 'Sponsor'} />
            </Link>
          ) : (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img src={logoImage} alt={text ?? 'Sponsor'} />
          )}
        </div>
      )}
    </div>
  );
};
