'use client';
import { useState, useEffect, useContext } from 'react';
import Script from 'next/script';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { useQueryParam } from '../../../customHooks/useQueryParam';

declare global {
  interface Window {
    MoltenBundle: {
      Video: {
        getSXPKeysAsync: () => Promise<string>;
      };
    };
  }
}

export const MoltenBundle = () => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  const isGujAdPreview = !!useQueryParam('gujAd');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isTopScriptLoaded, setIsTopScriptLoaded] = useState(false);

  const SCRIPT_TOP = isRefresh ? 'https://www.rtl.de/mbundle/top_v2.js' : 'https://www.rtl.de/mbundle/top.js';
  const SCRIPT_BOTTOM = isRefresh ? 'https://www.rtl.de/mbundle/bottom_v2.js' : 'https://www.rtl.de/mbundle/bottom.js';

  const SCRIPT_PREVIEW_TOP = isRefresh
    ? 'https://adctrl.emsmobile.de/molten/preview/rtl.de/MoltenBundle.js'
    : 'https://adctrl.emsmobile.de/molten/preview/neu.rtl.de/MoltenBundle.top.js';
  const SCRIPT_PREVIEW_BOTTOM = isRefresh
    ? 'https://adctrl.emsmobile.de/molten/preview/rtl.de/MoltenBundle.bottom.js'
    : 'https://adctrl.emsmobile.de/molten/preview/neu.rtl.de/MoltenBundle.bottom.js';

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (pageLoaded) {
    return (
      <>
        <Script
          id="molten-top"
          src={isGujAdPreview ? SCRIPT_PREVIEW_TOP : SCRIPT_TOP}
          onLoad={() => setIsTopScriptLoaded(true)}
          async
        />

        {isTopScriptLoaded && (
          <Script
            id="molten-bottom"
            strategy="afterInteractive"
            src={isGujAdPreview ? SCRIPT_PREVIEW_BOTTOM : SCRIPT_BOTTOM}
            async
          />
        )}
      </>
    );
  }

  return null;
};
